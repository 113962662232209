import  { EventToast } from "./events";
import { simpleNotificationToast } from "./template/toast_notification";
import { CONSENT_CONFIG_COOKIE_NAME } from "./config";
import Cookies from "js-cookie";

// https://davidwalsh.name/javascript-debounce-function
export function debounce(func, wait, immediate) {
  var timeout;
  return function() {
    var context = this,
      args = arguments;
    var later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

// function to bind key event listener.
export function keyBinding(selector, {enterAction}) {
  if(selector) {
    selector.addEventListener("keydown", e => {
      // keyboard event code
      switch(e.which) {
        case 13:
          enterAction && enterAction();
          break;
        default:
          null // do nothing
      }
    })
  }
}

// function to dispatch toast message event.
export function dispatchToast(message) {
  window.dispatchEvent(
    new CustomEvent(EventToast,
      {
        detail: {
          notice: simpleNotificationToast(message)
        }
      }
    )
  )
}

export function getCSRFToken() {
  return document.querySelector('meta[name="csrf-token"]')?.content;
}

export async function updateCsrfMeta() {
  const response = await fetch("/accounts/csrf_meta.json", { credentials: "same-origin" })
  if (!response.ok) {
    return { param: null, token: null };
  }

  const data = await response.json();
  return {
    param: data.param,
    token: data.token
  };
}

export async function setUpdatedCsrfMetaContent(csrfMeta) {
  if (!csrfMeta) {
    csrfMeta = await updateCsrfMeta();
  }

  const csrfTokenMetaTag = document.querySelector('meta[name="csrf-token"]');
  if (csrfTokenMetaTag) {
    csrfTokenMetaTag.setAttribute('content', csrfMeta.token);
  }

  const csrfParamMetaTag = document.querySelector('meta[name="csrf-param"]');
  if (csrfParamMetaTag) {
    csrfParamMetaTag.setAttribute('content', csrfMeta.param);
  }

  const inputTags = document.querySelectorAll('input[name="authenticity_token"]')
  if (inputTags) {
    inputTags.forEach((tag) => {
      tag.setAttribute('value', csrfMeta.token);
    });
  }
}

export function kebabFromCamel(input) {
  return input.replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase();
}

export function getCookie(name) {
  var value = "; " + document.cookie;
  var parts = value.split("; " + name + "=");
  if (parts.length == 2) return parts.pop().split(";").shift();
}

export const throttle = (func, wait, trailing = false) => {
  let time = trailing ? 0 : Date.now();
  return () => {
    if ((time + wait - Date.now()) < 0) {
      func();
      time = Date.now();
    }
  }
}

// V2: Deprecated; use sortable instead.
export const sortWithTableSort = (tables) => {
  // if (tables.length == 0) {
  //   return;
  // }
  // Promise.all([import("tablesort")])
  // .then(res => [res[0], import("tablesort/src/sorts/tablesort.number")])
  // .then(res => {
  //   window.Tablesort = res[0].default;
  //
  //   tables.forEach(target => {
  //     new Tablesort(target, {
  //       descending: true
  //     });
  //   });
  // });
}

export const convertAlpineTransitions = (element, remove = false) => {
  Array.from(element.attributes).forEach(attr => {
    if (!attr.nodeName.startsWith("x-transition:")) {
      return;
    }

    if (remove === true) {
      element.removeAttribute(attr.nodeName)
    }

    element.setAttribute(attr.nodeName.replace("x-transition:", "data-transition-"), attr.nodeValue);
  });
}

export const deltaTag = (number, decimal) => {
  let deltaTag;

  if (number === null || number === undefined) {
    deltaTag = "-"
  } else {

    let percentage = Number(number).toFixed(decimal);
    let classes;
    let iconClasses;

    if (percentage > 0) {
      classes = "tw-text-success-500 dark:tw-text-success-400"
      iconClasses = "fas fa-fw fa-caret-up"
    } else {
      classes = "tw-text-danger-500"
      iconClasses = "fas fa-fw fa-caret-down"
      percentage = percentage * -1
    };

    deltaTag = `<span class="${classes} tw-break-words">
                  <i class="${iconClasses}"></i>${percentage}%
                </span>`;
  };

  return deltaTag;
};

export const castNullToText = (data, text) => {
  return data ?? text;
};

export const isMobileDevice = (userAgent) => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
};

export const castToTarget = (e) => {
  return e.nodeType === Node.ELEMENT_NODE ? e : e.target;
}

export const readConsentConfig = (categoryName) => {
  const dataString = Cookies.get(CONSENT_CONFIG_COOKIE_NAME);

  if (!dataString) {
    console.error("no consent config, check the config cookie name");
    return false;
  }

  const consentConfigs = dataString.split("&");
  let consentCategories;

  for (let i = 0; i < consentConfigs.length; i++) {
    let pair = consentConfigs[i].split("=");
    if (pair[0] === "groups" && pair[1]) {
      consentCategories = pair[1].split(",");
      break;
    }
  }

  if (consentCategories) {
    let matchedCategory = consentCategories.find((e) => e.match(categoryName));
    if (matchedCategory) {
      return matchedCategory.split(":")[1] === "1";
    } else {
      return false;
    }
  }
}

export const getRecentSearchIds = (count = null) => {
  const recentSearchesIds = sanitizeRecentSearchesLocalStorage();
  if (count !== null) {
    return recentSearchesIds.slice(0, count);
  } else {
    return recentSearchesIds;
  }
}

export const sanitizeRecentSearchesLocalStorage = () => {
  const storedData = localStorage.getItem("portfolio-coins-recent-searches");
  let recentSearches = JSON.parse(storedData);

  if (!Array.isArray(recentSearches)) {
    recentSearches = [];
  }

  recentSearches = recentSearches.filter(id => Number.isInteger(id));
  return recentSearches;
}
