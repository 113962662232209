export const EventCurrencyChanged = "coingecko-currency-changed";

export const EventTooltipLoaded = "gecko-tooltip-loaded"; // new dynamic tooltips loaded/injected into DOM
export const EventPriceLoaded = "coingecko-price-loaded"; // new price elements loaded/injected into DOM
export const EventPercentChangeLoaded = "coingecko-percent-change-loaded"; // new percent-change elements loaded/injected into DOM
export const EventPriceChanged = "coingecko-price-changed";

export const EventPriceChartReload = "coingecko-price-chart-reload";
export const EventPricePercentageChanged = "coingecko-price-percentage-changed";

export const EventAsyncPricePercentageChanged = "coingecko-async-price-percentage-changed";

export const EventPortfolioOverviewChart = "coingecko-portfolio-overview-chart"
export const EventPortfolioTransactionAdded = "gecko-portfolio-coin-transaction-added";
export const EventPortfolioCreated = "gecko-portfolio-created";

export const EventToast = "coingecko-toast";
export const EventDarkMode = "coingecko-darkmode";

export const EventResetTooltip = "coingecko-reset-tooltip";

export const EventScroll = "coingecko-scroll";

export const EventCheckUserLogin = "coingecko-check-user-login";
export const EventTickersMoreLoaded = "coingecko-tickers-more-loaded";

export const EventFocusSearchGlobal = "coingecko-focus-search-global";

export const EventPrivacyModeToggled = "coingecko-privacy-mode-toggled";

export const EventShowFDV = "coingecko-show-fdv";

export const EventGeckoChartRendered = "gecko-chart-rendered";
export const EventGeckoChartNavigatorAdjusted = "gecko-chart-navigator-adjusted";
export const EventGeckoPieChartExpanded = "gecko-pie-chart-expanded";

export const EventMoreContentLoaded = "gecko-more-content-loaded";

export const EventGeckoPrimerLoaded = "gp-loaded";

export const EventSelectPortfolio = "coingecko-coins-portfolio-summary-card"
export const EventTogglePortfolioSummary = "coingecko-toggle-portfolio-summary"
export const EventViewPortfolioSummaryCard = "view_portfolio_summary_card";
export const EventHidePortfolioSummaryCard = "hide_portfolio_summary_card";

export const EventFocusPortfolioCoinSearch = "coingecko-focus-portfolio-coin-search";
export const EventSelectStaticDropdownAction = "static-dropdown-action";

export const EventPerformanceCookieConsentChanged = "coingecko-performance-cookie-consent-changed";

export const EventMixpanelInitLoaded = "coingecko-mixpanel-init-loaded";

export const EventFetchPortfolio = "coingecko-fetch-portfolio";
export const EventLoadAddCoinsSection = "coingecko-onboarding-add-coins-section";
export const EventLoadChainsChips = "coingecko-onboarding-chains-chips";
export const EventLoadCategoriesChips = "coingecko-onboarding-categories-chips";

export const EventOnboardingDropdown = "coingecko-onboarding-portfolio-dropdown";
export const EventOnboardingAddCoin = "coingecko-onboarding-add-coin";
export const EventOnboardingRemoveCoin = "coingecko-onboarding-remove-coin";
export const EventTabItemPointerentered = "gecko-tab-item-pointerentered";

export const EventContinueToPaymentClick = "continue-to-payment-click";

export const GeckoPrimerInputError = (show, errorMessage = null) => new CustomEvent("gp-input-error", {
  bubbles: true,
  detail: {show, errorMessage}
});

export const ProgressIndicatorStep = (next) => new CustomEvent("gp-progress-indicator-step", {
  detail: { next }
});

export const ChipSelect = (identifier) => new CustomEvent("gp-chip-select", {
  detail: { identifier }
});
